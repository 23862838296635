<template>
  <div class="information">

  <Navbar title="我的资料" needBack :needRight="false" background="white"></Navbar>


    <div class="isbtbtn">
      <van-cell-group :border="false">
        <!-- <div class="feature">
                    <div class="left">
                      <span class="browser-icon iconfont icon-gerenyonghutouxiang"></span>
                      <span>头像</span>
                    </div>
                    <div class="right rightImage" @click="show = true">
                      
                      <div class="item-image" v-if="!uploadImgUrl">
                        <img :src="userInfo.headUrl" alt="" v-if="userInfo.headUrl" />
                      </div>
                      <div class="item-image" v-else>
                        <img :src="uploadImgUrl" alt="" />
                      </div>
                      <van-icon name="arrow" color="#AEAEAE" class="arrow" />
                    </div>
              
                  </div> -->

        <van-cell
          title="昵称"
          :value="userInfo.name"
          is-link
          @click="goChange('changeName')"
          class="dark_shop_bg bottom-solid"
        >
          <!-- <p slot="icon" style="width: 0.48rem; margin-right: 0.32rem">
                        <img src="@/assets/images/yunpin.png" style="width: 100%"/>
                    </p> -->
        </van-cell>

        <van-cell
          title="职业"
          :value="userInfo.job"
          @click="goChange('changeJob')"
          is-link
          class="dark_shop_bg bottom-solid"
        ></van-cell>
        <van-cell
          title="手机号"
          :value="userInfo.mobile"
          to="/phone"
          is-link
          class="dark_shop_bg bottom-solid"
          :class="{dot:!userInfo.mobile&&!loading}"
        ></van-cell>
        <van-cell
          is-link
          :value="userInfo.genderName"
          title="性别"
          @click="showSex = true"
          class="dark_shop_bg bottom-solid"
        />
        <!-- <van-cell title="选择单个日期" :value="sex" @click="showSex = true" /> -->
        <van-cell
          is-link
          title="生日"
          :value="userInfo.birthday"
          @click="showDate = true"
          class="dark_shop_bg bottom-solid"
        />
      </van-cell-group>
    </div>

    <van-action-sheet
      v-model="showSex"
      :actions="actionsSex"
      @select="saveSex"
      cancel-text="取消"
      @cancel="onCancel"
    />
    <!-- <van-calendar v-model="showDate"  :min-date="minDate" :default-date='defaultDate' @confirm="onConfirm" :show-confirm="false"/> -->

    <van-datetime-picker
      v-if="showDate"
      v-model="currentDate"
      type="date"
      title="选择年月日"
      :min-date="minDate"
      :max-date="maxDate"
      @cancel="cancelDate"
      @confirm="onConfirm"
    />

    <!-- 拍照弹窗 -->
    <van-action-sheet
      v-model="show"
      :actions="actions"
      @select="onSelect"
      close-on-click-action
      cancel-text="取消"
      @cancel="onCancelPhone"
    />
    <!-- :capture="capture" -->
    <van-uploader
      v-model="fileList"
      ref="uploader"
      style="display: none"
      :after-read="afterRead"
      :capture="captureIS"
      accept="image/*"
    />
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      names: "张三",
      userInfo: {},
      // refreshCustomer: this.Base.refreshCustomer,
      show: false,
      actions: [
        // { name: "请选择", disabled: true },
        { name: "拍照", color: "#3291f8" },
        { name: "从相册获取", color: "#3291f8" },
        // { name: "取消", color: "#3291f8" },
      ],
      actionsSex: [
        { name: "男", genderCode: 0 },
        { name: "女", genderCode: 1 },
        { name: "未知", genderCode: 2 },
      ],
      //选择生日
      showDate: false,
      minDate: new Date(1900, 0, 1),
      defaultDate: new Date(2000, 0, 1),

      userInfo: {
        id: "",
        date: "",
        sex: "",
        mobile: "",
        job: "",
        birthday: "",
        name: "",
      },

      capture: "",
      captureIS: "",
      fileList: [],
      // 头像
      headUrl: {
        img: [],
        id: "",
      },
      uploadImgUrl: "",
      showSex: false,

      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      loading:false
    };
  },
  created() {
    // console.log(this.captureIS);
    localStorage.removeItem("routeFromMy");
    // this.$toast.loading({
    //   message: "加载中...",
    //   forbidClick: true,
    // });
    // setTimeout(() => {
    //   this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //   this.$toast.clear();
    // }, 1000);
    this.getUser();
    // this.is_weixn();
    // this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // this.id = this.userInfo.id;
  },
  mounted() {},
  methods: {
    cancelDate() {
      this.showDate = false;
    },
    confirmDate() {},
    //修改性别
    saveSex(e) {
      console.log(e);
      this.userInfo.genderCode = e.genderCode;
      this.userInfo.sexName = e.name;

      this.$api.my.changeDetail(this.userInfo).then((res) => {
        if (res.code === 200) {
          console.log("修改性别");
          this.getUser();
        }
      });
      //  this.$api.my
      //       .userEdit({
      //         userId:localStorage.customerId,
      //         gender: e.sex,
      //       }).then(res=>{
      //           if(res.errno === 200){
      //               this.$toast('修改成功')
      //               // this.$router.go(-1)
      //           }
      //       })
      this.showSex = false;
    },
    onCancel() {
      this.showSex = false;
    },
    onCancelPhone() {
      this.show = false;
    },
    //选择生日
    formatDate(date) {
      console.log(date);
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      console.log(date);
      this.showDate = false;
      this.userInfo.birthday = this.formatDate(date);
      this.$api.my.changeDetail(this.userInfo).then((res) => {
        if (res.code === 200) {
          console.log("修改生日");
        }
      });
    },

    //获取昵称头像
    getUser() {
      this.loading=true
      this.userInfo = {};
      this.$api.my.getUserDetail(localStorage.id).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
      
      this.loading=false

        }
      });
    },
    //前往修改
    goChange(url) {
      this.$router.push({
        name: url,
        query: this.userInfo,
      });
    },

    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    // 显示弹出框
    showPhoto() {
      // if (this.headUrl.img.length) {
      //   this.handleDialog();
      // } else {
      //   this.show = true;
      // }
      this.show = true;
    },
    onSelect(e) {
      console.log(e);
      // if (e.name == "取消") {
      //   this.show = false;
      //   return;
      // }
      // if (e.name == "拍照") {
      //   // this.captureIS = "camera";
      //   this.captureIS = '';

      //   alert(this.captureIS)
      // } else if (e.name == "从相册获取") {
      //   this.captureIS = '';
      //   alert(this.captureIS)
      // }
      this.$refs.uploader.chooseFile();
    },
    async afterRead(e) {
      console.log("afterRead", e);
      this.frontidImgUrl = e.content;
      this.show = false;
      let appendData = new FormData();
      appendData.append("file", e.file);
      appendData.append("idCode", 1);
      console.log(appendData);
      await this.$api.my.fileUploadAddress(appendData).then((res) => {
        this.$toast.clear();
        if (res.errno === 200) {
          // this.headUrl.img.push({ url: res.url });
          this.uploadImgUrl = res.url;
          this.$api.my
            .userEdit({
              // headUrl: this.headUrl.img[0].url,
              userId: localStorage.customerId,
              avatarUrl: res.url,
              // id: this.id,
            })
            .then((res) => {
              // console.log("头像", res);
              if (res.errno === 200) {
                // this.refreshCustomer();
                this.$toast.success("保存成功");
              }
            });
          // this.$toast("上传成功");
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 删除确认弹窗
    handleDialog() {
      let _this = this;

      Dialog.confirm({
        title: "提示",
        message: "是否删除已上传的附件",
      })
        .then(() => {
          // on confirm
          this.headUrl.img = [];
          let userInfo = {
            ...this.userInfo,
            headUrl: "",
          };
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
        })
        .catch(() => {
          // on cancel
        });
    },
    openCamera() {
      console.log("openCamera");
      // if (this.headUrl.img.length) {
      //   this.handleDialog();
      // }
      let _this = this;
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ["compressed"], // 指定是原图还是压缩图，默认都有
        sourceType: ["album", "camera"], // 指定来源是相册还是相机，默认都有
        success: function (res) {
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          wx.getLocalImgData({
            localId: localIds.toString(), // 图片的localID
            success: function (res) {
              var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
              if (localData.indexOf("data:image") != 0) {
                // 判断是否有这样的头部
                localData = "data:image/jpeg;base64," + localData;
              }
              localData = localData
                .replace(/\r|\n/g, "")
                .replace("data:image/jgp", "data:image/jpeg");
              var file = _this.dataURLtoFile(localData, "头像.jpg"); // 转换格式
              var params = new FormData();
              params.append("file", file);
              params.append("accessToken", localStorage.getItem("token"));
              params.append("idCode", 1);
              console.log("params", params);
              _this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "上传中",
              });
              _this.$api.my.uploadIDCredit(params).then((res) => {
                _this.$toast.clear();
                if (res.code === 200) {
                  // _this.headUrl.img.push({ url: res.url });
                  _this.uploadImgUrl = res.url;
                  _this.$api.my
                    .updateUserAddress({
                      // headUrl: _this.headUrl.img[0].url,
                      headUrl: res.url,
                      id: _this.id,
                    })
                    .then((res) => {
                      console.log("头像", res);
                      if (res.code === 200) {
                        // _this.refreshCustomer();
                        _this.$toast.success("保存成功");
                      }
                    });
                  // _this.$toast("上传成功");
                } else {
                  _this.$toast(res.msg);
                }
              });
            },
            fail: function (res) {
              alertModal("图片上传失败，请重试");
            },
          });
        },
      });
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.isWeix = true;
        this.handleWx();
      } else {
        this.isWeix = false;
      }
    },
    handleWx() {
      var timestamp = this.timest();
      var nonceStr = this.randomString(15);
      var url = location.href.split("#")[0];
      this.$api.my
        .getWxSignature({
          noncestr: nonceStr,
          timestamp,
          url,
        })
        .then((res) => {
          if (res.code === 100) {
            let signature = res.msg;
            let _this = this;
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来,测试完成后需要关闭。
              appId: process.env.VUE_APP_APP_ID, // 必填，公众号的唯一标识
              timestamp: timestamp, // 必填，生成签名的时间戳
              nonceStr: nonceStr, // 必填，生成签名的随机串
              signature: signature, // 必填，签名
              jsApiList: ["chooseImage", "getLocalImgData"], // 必填，注册需要使用的JS接口列表
            });
          }
        });
    },
    // 生成随机串
    randomString(len) {
      len = len || 32;
      var $chars =
        "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      var maxPos = $chars.length;
      var pwd = "";
      for (var i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    // 生成秒级时间戳
    timest() {
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);
      return tmp;
    },
    // 将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    goNickname() {
      this.$router.push({
        path: "/nickname",
      });
    },

    goMineFirm() {
      this.$router.push({
        path: "/firm",
      });
    },

    goBack() {
      this.$router.replace({
        path: "/my",
      });
    },
    geTel(tel) {
      if (tel) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return tel.replace(reg, "$1****$2");
      }
    },
    geIdCard(tel) {
      if (tel) {
        let tels = tel.toString();
        var reg = /^(\d{1})\d{16}(\d{1})$/;
        return tels.replace(reg, "$1****************$2");
      }
    },
    // 退出登录
    loginOut() {
      var userId = localStorage.getItem("customerId");
      this.$api.login
        .loginOutAddress({
          userId,
        })
        .then((res) => {
          if (res.errno === 200) {
            this.$toast({
              message: "退出成功",
              forbidClick: true,
              duration: 800,
              onClose: () => {
                if (localStorage.storeId) {
                  this.$router.push("indexAlone");
                } else {
                  this.$router.push("index");
                }
              },
            });
            // 清除token
            localStorage.removeItem("token");
            localStorage.removeItem("customerLevel");
            localStorage.removeItem("customerName");
            localStorage.removeItem("mobile");
            // 修改customerID
            localStorage.removeItem("customerId");
            removeToken();
          } else {
            this.$toast.fail("退出失败，不可预知的错误");
          }
        });
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.dot {
  .van-icon {
    &::after {
      content: "";
      position: absolute;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      background: red;
      right: 0.02rem;
      top: 0.05rem;
    }
  }
}
.information {
  width: 100vw;
  height: 100vh;
  background: #ffffff;

  .feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.26rem /* 13/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #464646;
    background-color: #ffffff;
    border-bottom: 1px solid #efefef;
    // padding: 0.4rem /* 20/50 */ 0;
    box-sizing: border-box;
    // margin: 0 0.32rem /* 16/50 */;
    padding: 0.4rem 0 0.38rem /* 16/50 */;

    .left {
      display: flex;
      align-items: center;
      > span {
        font-size: 0.28rem /* 14/50 */;
        font-family: PingFang SC;
        font-weight: 500;
        // color: #7e7e7e;
        color: #333;
      }
      .browser-icon {
        font-size: 20px;
        // margin-right: 10px;
      }
    }
    .right {
      width: 4rem /* 200/50 */;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .item-image {
        width: 0.66rem /* 33/50 */;
        height: 0.66rem /* 33/50 */;
        margin-right: 0.2rem /* 10/50 */;
        background: #ffffff;
        box-shadow: 0px 3px 8px 0px rgba(20, 38, 86, 0.1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .value-box {
        width: 80%;
        overflow: scroll;
        font-size: 0.28rem /* 14/50 */;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333;
        margin-right: 0.2rem /* 10/50 */;
        text-align: right;
      }
      .arrow {
        font-size: 0.32rem /* 16/50 */;
      }
    }
    .rightImage {
      width: 1.7rem /* 85/50 */;
    }
  }
  .mt40 {
    margin-top: 40px;
  }
}
.isbtbtn {
  background-color: #ffffff;
  padding: 0 0.3rem 0;
}
/deep/ .van-cell {
  // color: #333;
  font-size: 0.28rem /* 13/50 */;
  font-family: PingFang SC;
  font-weight: 500;
  color: #464646;
}
.quit {
  position: fixed;
  bottom: 0.38rem;
  left: 50%;
  transform: translate(-50%);
  width: 6.9rem;
  height: 0.9rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(20, 20, 20, 0.05);
  border-radius: 0.1rem;
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.36rem;
}
// .van-action-sheet__content{
//   padding: .3rem 0 2rem;
// }
.van-cell {
  padding: 0.4rem 0 0.38rem 0 /* 16/50 */;
  &::after {
    border-bottom: 0;
  }
}
.bottom-solid {
  border-bottom: 1px solid #efefef;
}
</style>
